import ChillStreets from "../songs/90's Hip Hop/Chill Streets - Andreas Potgieter.wav";
import DangerousWork from "../songs/90's Hip Hop/It's Dangerous Work - Andreas Potgieter.wav";
import BurningSneakers from "../songs/90's Hip Hop/Burning Sneakers - Andreas Potgieter.wav";

import AStepAway from "../songs/Acoustic/A Step Away - Andreas Potgieter.mp3";
import DownByTheRiver from "../songs/Acoustic/Down by the River - ANVR.mp3";
import GlowingInGold from "../songs/Acoustic/Glowing in Gold - ANVR.mp3";

import DontWorryBeHappy from "../songs/Ad Concepts/Don't Worry Be Happy Whistled - Andreas Potgieter.mp3";
import EngineSounds from "../songs/Ad Concepts/Engine Sounds x Black Skinhead - Andreas Potgieter.mp3";
import Rocketman from "../songs/Ad Concepts/Rocketman Cover - Andreas Potgieter.mp3";
import TheShiftingSand from "../songs/Ad Concepts/The Shifting Sand - Andreas Potgieter.mp3";
import Yumeji from "../songs/Ad Concepts/Yumeji's Theme Interwoven with Nissan car sounds.wav";

import AnAfricanDream from "../songs/Afro Acoustic Pop/An African Dream - The Light Life Factory.wav";
import TheBirdOfPara from "../songs/Afro Acoustic Pop/The Bird of Paradise - The Light Life Factory.wav";
import TheKingfisher from "../songs/Afro Acoustic Pop/The Kingfisher - The Light Life Factory.wav";
import TheLargeeMouthedFrog from "../songs/Afro Acoustic Pop/The Large Mouthed Frog - The Light Life Factory.wav";
import TheRainMan from "../songs/Afro Acoustic Pop/The Rain Man - The Light Life Factory.wav";

import BringItHome from "../songs/Anthemic Electro Rock/Bring It Home - Andreas Potgieter.wav";

import AnAfternoonOut from "../songs/Back to School/An Afternoon Out - Andreas Potgieter.wav";
import WhistleAlong from "../songs/Back to School/Whistle Along - Andreas Potgieter.wav";

import ActionLLF from "../songs/Big Hair Metal/Action - Andreas Potgieter.wav";

import ViceCityPalmTrees from "../songs/Club _ Dance/Vice City Palm Trees - The Light Life Factory.wav";
import High from "../songs/Club _ Dance/High - The Light Life Factory.mp3";

import Breachv2 from "../songs/Dark & Eerie/The Breach - Andreas Potgieter.wav";
import Hallows from "../songs/Dark & Eerie/Hallows Break - Andreas Potgieter.wav";

import ADarkNight from "../songs/Dark Electronic _ Dance/A Dark Night - The Light Life Factory.wav";
import BraidedBones from "../songs/Dark Electronic _ Dance/Braided Bones - The Light Life Factory.wav";
import Infrared from "../songs/Dark Electronic _ Dance/Infrared - The Light Life Factory.wav";
import Nighteyes from "../songs/Dark Electronic _ Dance/Nighteyes - The Light Life Factory.wav";

import SpicedUp from "../songs/Experimental Rock/Spiced Up - Andreas Potgieter.mp3";

import FeelIt from "../songs/Fierce/Feel It - Andreas Potgieter.wav";
import GoldLLF from "../songs/Fierce/Gold - Andreas Potgieter.wav";
import NotForASecond from "../songs/Fierce/Not For a Second - The Light Life Factory.wav";

import HoldHer from "../songs/Folk Rock _ Emotive Rock/Hold Her - Rauc.wav";
import IllHoldYou from "../songs/Folk Rock _ Emotive Rock/I'll Hold You Whole  - Andreas Potgieter.mp3";

import BoundToTheRhythm from "../songs/Funk/Bound to the Rhythm - Andreas Potgieter.wav";

import Boom from "../songs/Hard Hip Hop/BOOM - Andreas Potgieter.wav";
import Preach from "../songs/Hard Hip Hop/Preach - Andreas Potgieter.wav";

import Breakers from "../songs/Heist/Breakers - Andreas Potgieter.wav";
import TheBackwardBeat from "../songs/Heist/The Backward Beat - Andreas Potgieter.wav";

import BreachV1 from "../songs/Moody Cinematic/Breach - Andreas Potgieter.wav";
import Breene from "../songs/Moody Cinematic/Breene - Andreas Potgieter.wav";
import ABeautifulLife from "../songs/Moody Cinematic/A Beautiful Life - The Light Life Factory.wav";
import BeautifulDaydream from "../songs/Moody Cinematic/A Beautiful Daydream - Andreas Potgieter.wav";
import SIX from "../songs/Moody Cinematic/SIX - Andreas Potgieter.wav";

import ThroughTheMist from "../songs/Piano/Through The Mist - Andreas Potgieter.mp3";

import Quicksand from "../songs/Upbeat_Feel Good Pop/Quicksand - ANVR.wav";
import TogetherWeBelong from "../songs/Upbeat_Feel Good Pop/Togther We Belong - The Light Life Factory.wav";

import BraceForImpact from "../songs/Urban/Brace for Impact - The Light Life Factory.wav";
import DoingDeals from "../songs/Urban/Doing Deals - The Light Life Factory.wav";

export const MusicConfig = [
  {
    genre: "Piano",
    playlist: [
      {
        title: "Through The Mist - Andreas Potgieter",
        desc: `This instrumental piano journey is both
        mysterious and enticing. Embodying feelings of
        things forgotten, things done behind closed
        doors, or things yet to come`,
        elements: `Elements: Piano`,
        url: ThroughTheMist,
        genre: "Piano",
      },
    ],
  },
  {
    genre: "Acoustic",
    playlist: [
      {
        title: "A Step Away - Andreas Potgieter",
        desc: `This peaceful instrumental piece is an easy ride,
        taking you on a pleasant journey, happy and
        calming. Subtle and ethereal`,
        elements: `Elements:
        Steel String Guitar, Piano, Percussion`,
        url: AStepAway,
        genre: "Acoustic",
      },
      {
        title: "Down by the River - ANVR",
        desc: `This is a heartfelt and deeply personal song
        about acceptance of being fallible and learning
        to let go`,
        elements: `Elements:
        Vocals, Acoustic Guitar`,
        url: DownByTheRiver,
        genre: "Acoustic",
      },
      {
        title: "Glowing in Gold - ANVR",
        desc: `This is a deep and moving song about the
        difficult parts of love`,
        elements: `Elements:
        Vocals, Acoustic Guitar`,
        url: GlowingInGold,
        genre: "Acoustic",
      },
    ],
  },
  {
    genre: "Fierce",
    playlist: [
      {
        title: "Not For a Second - The Light Life Factory",
        desc: `This is a modern, hard-hitting and impactful
        track pushing to not back down and drive
        forward no matter what anyone says`,
        elements: `Elements:
        Affected Vocals, Synth Percussion, Synth, Piano,
        Sound Design, Drum Machine`,
        url: NotForASecond,
        genre: "Fierce",
      },
      {
        title: "Feel It - Andreas Potgieter",
        desc: `This track has a dangerous nature, the voice of
        the crowd - driving and not afraid to show it’s
        true colours`,
        elements: `Elements:
        Bass guitar, Affected vocals, Synth, Percussion,
        Acoustic percussion, Drum machine`,
        url: FeelIt,
        genre: "Fierce",
      },
      {
        title: "Gold - Andreas Potgieter",
        desc: `This is a more modern, alternative, hard hitting
        track. Urban, frenetic, high energy – pushing you
        somewhere new and exciting`,
        elements: `Elements:
        Vocal samples, Synths, Samples, Drum Machine`,
        url: GoldLLF,
        genre: "Fierce",
      },
    ],
  },
  {
    genre: "Experimental Rock",
    playlist: [
      {
        title: "Spiced Up - Andreas Potgieter",
        desc: `An unapologetic rock track about being ready to
        take on what life throws, and enjoying the ride`,
        elements: `Elements:
        Bass Guitar, Electric Guitar, Drum Kit, Vocals`,
        url: SpicedUp,
        genre: "Experimental Rock",
      },
    ],
  },

  {
    genre: "Anthemic Electro Rock",
    playlist: [
      {
        title: "Bring It Home - Andreas Potgieter",
        desc: `A mix of synths and rock elements, this anthemic
        track, meant for stadiums, is about seizing the
        day and letting nothing get in the way of what
        you want`,
        elements: `Elements:
        Vocals, Electric Guitar, Drum Kit, Synths, Drum
        Machine`,
        url: BringItHome,
        genre: "Anthemic Electro Rock",
      },
    ],
  },
  {
    genre: "Heist",
    playlist: [
      {
        title: "Breakers - Andreas Potgieter",
        desc: `When the stakes are high, and the reward is big.
        Ocean’s Eleven Final act`,
        elements: `Elements:
        Bass Guitar, Percussion, Electric Guitar, Drum Kit`,
        url: Breakers,
        genre: "Heist",
      },
      {
        title: "The Backward Beat - Andreas Potgieter",
        desc: `Slick, cool, unfazed. Planning the perfect crime
        or cruising through life. Untouchable. Feeling
        your groove`,
        elements: `Elements:
        Bass Guitar, Vocals, Electric Guitar, Drum Kit`,
        url: TheBackwardBeat,
        genre: "Heist",
      },
    ],
  },
  {
    genre: "Big Hair Metal",
    playlist: [
      {
        title: "Action - Andreas Potgieter",
        desc: `Stadium Rock out of the 80’s, calling action.
        Sweeping burnt guitars, big energy, big takes`,
        elements: `Elements:
        Electric Guitar, Bass Guitar, Drum Kit, Vocals`,
        url: ActionLLF,
        genre: "Big Hair Metal",
      },
    ],
  },
  {
    genre: "Club Dance",
    playlist: [
      {
        title: "High - The Light Life Factory",
        desc: `  Uplifting and transcendent, this dance track is
        peaceful, happy and uplifting. To have you
        floating but also dancing along with a smile on
        your face`,
        elements: `Elements:
        Sampled Vocals, Drum Machine, Synths`,
        url: High,
        genre: "Club Dance",
      },
      {
        title: "Vice City Palm Trees - The Light Life Factory",
        desc: `A modern catchy, happy, summertime dance
        track. Nostalgic for the good times under the
        sun`,
        elements: `Elements:
        Electric Guitar, Drum Machine, Vocals, Sampled
        Vocals, Synths, Affected Vocals`,
        url: ViceCityPalmTrees,
        genre: "Club Dance",
      },
    ],
  },

  {
    genre: "90's Hip Hop",
    playlist: [
      {
        title: "Chill Streets - Andreas Potgieter",
        desc: `A cool Hip Hop beat reminiscent of the 90’s.
        Urban city streets with a story to tell`,
        elements: `Elements:
        Synths, Drum Machine, Electric Guitar`,
        url: ChillStreets,
        genre: "90's Hip Hop",
      },
      {
        title: "It's Dangerous Work - Andreas Potgieter",
        desc: `An impactful beat with attitude and an agenda.
        Not backing down, and standing up`,
        elements: `Elements:
        Synths, Trumpet Samples, Drum Machine, Piano`,
        url: DangerousWork,
        genre: "90's Hip Hop",
      },
      {
        title: "Burning Sneakers - Andreas Potgieter",
        desc: `Spacey guitars coupled with salty saturated
        drums and a smooth gliding pace make this track
        a consistent walk uninhibited`,
        elements: `Elements:
        Electric Guitar, Synths, Drum Kit, Percussion,
        Drum Machine, Samples`,
        url: BurningSneakers,
        genre: "90's Hip Hop",
      },
    ],
  },
  {
    genre: "Funk",
    playlist: [
      {
        title: "Bound to the Rhythm - Andreas Potgieter",
        desc: `Slick and easy, nothing to worry about when
        finding that groove`,
        elements: `Elements:
        Electric Guitar, Bass Guitar, Percussion, Drum
        Kit, Vocals`,
        url: BoundToTheRhythm,
        genre: "Funk",
      },
    ],
  },
  {
    genre: "Folk Rock/Emotive Rock",
    playlist: [
      {
        title: "I'll Hold You Whole - Andreas Potgieter",
        desc: `A song about being there for someone, and the
        human connection that binds us`,
        elements: `Elements: Vocals, Steel String Guitar, Violin, Cello, Drum
        Kit, Percussion`,
        url: IllHoldYou,
        genre: "Folk Rock/Emotive Rock",
      },
      {
        title: "Hold Her - Rauc",
        desc: `A deep emotive song about a relationship
        doomed to fail. Pulling on your heartstrings`,
        elements: `Elements:
        Vocals, Electric Guitar, Orchestral Strings, Piano,
        Bass Guitar`,
        url: HoldHer,
        genre: "Folk Rock/Emotive Rock",
      },
    ],
  },
  {
    genre: "Dark & Eerie",
    playlist: [
      {
        title: "The Breach - Andreas Potgieter",
        desc: `A modern take on Halloween, and all things
        dark`,
        elements: `Elements:
        Samples, Vocals, Percussion, Electric Guitar,
        Steel String Guitar, Synths, Drum Machine`,
        url: Breachv2,
        genre: "Dark & Eerie",
      },
      {
        title: "Hallows Break - Andreas Potgieter",
        desc: `The Castle on the Hill, brooding and shrouded in
        dark. The stomping of the night`,
        elements: `Elements:
        Affected Vocals, Violin, Percussion, Organ,
        Samples, Drum Machine`,
        url: Hallows,
        genre: "Dark & Eerie",
      },
    ],
  },
  {
    genre: "African",
    playlist: [
      {
        title: "An African Dream - Andreas Potgieter",
        desc: `Djembe’s, Electric guitar waves and peaceful
        vibes to cruise you through an African Dream`,
        elements: ``,
        url: AnAfricanDream,
        genre: "African",
      },
      {
        title: "The Bird of Paradise - Andreas Potgieter",
        desc: `Happy and infectious in it’s vibe to get you
        smiling and feeling good energy`,
        elements: ``,
        url: TheBirdOfPara,
        genre: "African",
      },
      {
        title: "The Kingfisher - Andreas Potgieter",
        desc: `Explorative and darting with a fast and targeted
        energy to deliver pace and excitement`,
        elements: ``,
        url: TheKingfisher,
        genre: "African",
      },
      {
        title: "The Large Mouthed Frog - Andreas Potgieter",
        desc: `Serene, calming and magical - this track takes
        you to a peaceful place and transports you to an
        almost childlike wonder`,
        elements: ``,
        url: TheLargeeMouthedFrog,
        genre: "African",
      },
      {
        title: "The Rain Man - Andreas Potgieter",
        desc: `Stomping and infectiously happy - there to get
        you to move`,
        elements: ``,
        url: TheRainMan,
        genre: "African",
      },
    ],
  },
  {
    genre: "Hard Hip Hop",
    playlist: [
      {
        title: "BOOM - Andreas Potgieter",
        desc: `A hard and dangerous beat standing tall, loud
        and proud`,
        elements: `Elements:
        Synths, Affected Electric Guitar, Samples, Drum
        Machine`,
        url: Boom,
        genre: "Hard Hip Hop",
      },
      {
        title: "Preach - Andreas Potgieter",
        desc: `A triumphant beat, ominous and proud.
        Overcoming and conquest`,
        elements: `Elements:
        Synths, Affected Piano, Samples, Drum Machine,
        Affected Vocals`,
        url: Preach,
        genre: "Hard Hip Hop",
      },
    ],
  },
  {
    genre: "Urban",
    playlist: [
      {
        title: "Brace for Impact - Andreas Potgieter",
        desc: `This is a confident and almost mischievous beat
        for the streets. Full of attitude and fast-paced
        drums`,
        elements: `Elements:
        Ukulele, Trumpet, Drum Kit, Vocals, Percussion,
        Bass Guitar, Affected Vocals`,
        url: BraceForImpact,
        genre: "Urban",
      },
      {
        title: "Doing Deals - Andreas Potgieter",
        desc: `This song starts with attitude from the get go –
        with trumpets that go boldly and a synth line to
        hold it all together`,
        elements: `Elements:
        Synths, Trumpets, Sampled Vocals, Drum Kit,
        Drum Machine`,
        url: DoingDeals,
        genre: "Urban",
      },
    ],
  },
  {
    genre: "Ad Concepts",
    playlist: [
      {
        title: "Engine Sounds x Black Skinhead - Andreas Potgieter",
        desc: `This concept used pieces of Black Skinhead
        (instrumental) as a base which was mixed with
        various motorised vehicle noises to create the
        soundtrack; such as F1 cars, other racing and
        commuter cars (both electric and fuel), jet skis,
        dirt bikes and a plane etc`,
        elements: ``,
        url: EngineSounds,
        genre: "Ad Concepts",
      },
      {
        title: "Don't Worry Be Happy Whistled - Andreas Potgieter",
        desc: `This concept was about multiple people in
        different environments whistling the song “Don't
        Worry Be Happy” – and as such the whistle can
        be heard to be carried out by different people in
        different settings; such as a textile factory, a
        science lab, a factory, a F1 track, a research lab,
        in the office, and outside etc. With at first a slow
        change and then a quick flutter between them`,
        elements: ``,
        url: DontWorryBeHappy,
        genre: "Ad Concepts",
      },
      {
        title: "Yumeji's Theme Interwoven with car sounds - Andreas Potgieter",
        desc: `This concept was that of a waltz combined with car sounds to become an integrated whole. The
        car sounds were chosen to follow the music very
        specifically, and in places supersede, to create
        an interwoven piece – representative of the
        concept of the ad. A waltz between two
        disparate elements to create something unique
        and special`,
        elements: ``,
        url: Yumeji,
        genre: "Ad Concepts",
      },
      {
        title: "The Shifting Sand - Andreas Potgieter",
        desc: `This concept was a blending of an Arabic style
        oud with string instruments in the style of a
        philharmonic orchestra with reference to
        “Vivaldi’s Four Seasons”. There is also the sense
        of space and the movement of sand and wind`,
        elements: ``,
        url: TheShiftingSand,
        genre: "Ad Concepts",
      },
      {
        title: "Rocketman Cover - Andreas Potgieter",
        desc: `A slower building version of Rocketman – Elton
        John, where it begins starkly with just an
        intimate guitar and then into an impactful
        chorus of a full band`,
        elements: ``,
        url: Rocketman,
        genre: "Ad Concepts",
      },
    ],
  },

  {
    genre: "Upbeat/Feel Good Pop",
    playlist: [
      {
        title: "Together We Belong - The Light Life Factory",
        desc: `An emotive and happy song about belong
        together. Clap and sing along – it starts with a
        infectious snap and an easy piano lead line`,
        elements: `Elements:
        Vocals, Piano, Steel String Guitar, Percussion,
        Drum Kit`,
        url: TogetherWeBelong,
        genre: "Upbeat/Feel Good Pop",
      },
      {
        title: "Quicksand - ANVR",
        desc: `This is a modern day pop love song - easy, feelgood and sing-along. Uncomplicated with lyrics
        that speak of love`,
        elements: `Elements:
        Vocals, Percussion, Ukulele, Guitar, Piano, Drum
        Kit, Synths`,
        url: Quicksand,
        genre: "Upbeat/Feel Good Pop",
      },
    ],
  },
  {
    genre: "Moody Cinematic",
    playlist: [
      {
        title: "Breene - Andreas Potgieter",
        desc: `Deep and reflective affected electric and bass
        guitars start this growing movement, which pulls
        you in with emotive vocals that harmonise and
        call out – it is a song with depth and deep emotions. A striking drum beat adds a biting
        dynamic. Perfect for that growing moment to
        build emotion`,
        elements: `Elements:
        Bass Guitar, Electric`,
        url: Breene,
        genre: "Moody Cinematic",
      },
      {
        title: "A Beautiful Life - The Light Life Factory",
        desc: `About overcoming and beating the obstacles
        ahead - this track has a hard bassline and a deep
        chest beat coupled with a dark vocal to make
        this track hit hard but gracefully. Pushing you
        forward to fighting for what is right against the
        clock`,
        elements: `Elements:
        Bass Guitar, Drum Kit, Vocals, Electric Guitar,
        Synths, Sound Design, Percussion`,
        url: ABeautifulLife,
        genre: "Moody Cinematic",
      },
      {
        title: "A Beautiful Daydream - Andreas Potgieter",
        desc: `Using the same bassline as “A Beautiful Life” but
        coupled with hard growling synths, synth rises
        and ‘almost voices’ revolving in a soundscape.
        Harder-hitting drums pierce through as well.
        This is a harder track with the same emotive
        feel, but more dystopian, think Blade Runner`,
        elements: `Elements:
        Bass Guitar, Synths, Vocals, Drum Kit,
        Percussion, Drum Machine, Electric Guitar`,
        url: BeautifulDaydream,
        genre: "Moody Cinematic",
      },
      {
        title: "Breach - Andreas Potgieter",
        desc: `Ominous picking, mangled guitar, strange
        sounds, and modern trap elements permeate
        this track which builds slow and then progresses
        to a harder grittier finish. A growing tension,
        powerful, unabridged, unapologetic. Dark in
        nature, but then also pensive or just powerful`,
        elements: `Elements:
        Steel String Guitar, Electric Guitar, Synths, Drum
        kit, Affected Vocals, Drum Machine, Bass Guitar`,
        url: BreachV1,
        genre: "Moody Cinematic",
      },
      {
        title: "SIX - Andreas Potgieter",
        desc: `This is an emotive journey through a landscape of sound, progressive and building. Muted and
        bass heavy throughout`,
        elements: `Elements:
        Synths, Piano, Drum Machine, Percussion, Synth
        Strings`,
        url: SIX,
        genre: "Moody Cinematic",
      },
    ],
  },
  {
    genre: "Back to School",
    playlist: [
      {
        title: "An Afternoon Out - Andreas Potgieter",
        desc: `A big friendly bassline kicks off this ‘Happy-Go-Lucky’, plodding along, happy track. Gentle and exploratory, sweet to the taste`,
        elements: `Elements:
        Bass Guitar, Keyboard, Piano, Percussion`,
        url: AnAfternoonOut,
        genre: "Back to School",
      },
      {
        title: "Whistle Along - Andreas Potgieter",
        desc: `Counter-parting a friendly happy whistle with a
        ukulele pattern this track is a happy sojourn with
        light feet`,
        elements: `Elements:
        Ukulele, Bass Guitar, Percussion, Whistling,
        Drum kit`,
        url: WhistleAlong,
        genre: "Back to School",
      },
    ],
  },
  {
    genre: "Dark Electronic/ Dance",
    playlist: [
      {
        title: "A Dark Night - The Light Life Factory",
        desc: `A mysterious and driving electro song with a fast
        paced energy, frenetic and dark`,
        elements: `Elements:
        Drum Machine, Synths, Electric guitar`,
        url: ADarkNight,
        genre: "Dark Electronic/Dance",
      },
      {
        title: "Braided Bones - The Light Life Factory",
        desc: `This heavy and broody track has deep energy
        saturated with tense vocals. It is almost mystical,
        and slowly builds and moves forward with a
        steady and determined pace`,
        elements: `Elements:
        Drum Machine, Vocals, Affected Vocals, Synths,
        Sampled Sound Design`,
        url: BraidedBones,
        genre: "Dark Electronic/Dance",
      },
      {
        title: "Infrared - The Light Life Factory",
        desc: `Fast-paced and heavy, this track is pounding at
        the walls, driving and tearing though. It has a
        dangerous nature coupled with a racing feel`,
        elements: `Elements:
        Affected Vocals, Drum Machine, Synths`,
        url: Infrared,
        genre: "Dark Electronic/Dance",
      },
      {
        title: "Nighteyes - The Light Life Factory",
        desc: `This track is a deep raw shout, emotive and
        heavy with a large impactful beat. Powerful and
        moving`,
        elements: `Elements:
        Affected Vocals, Synths, Drum Machine,
        Percussion`,
        url: Nighteyes,
        genre: "Dark Electronic/Dance",
      },
    ],
  },
];
