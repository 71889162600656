import React, { useState, useCallback, useRef } from "react";
import { graphql } from "gatsby";
import { Builder, builder, BuilderComponent } from "@builder.io/react";
import Link from "../components/Link/Link";
import { MusicConfig } from "../utils/MusicViewer.config";
import * as S from "../utils/MusicViewer.styles";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
builder.init(`331d613148d543b094cce7d4121df43c`);

function HomePage({ data }) {
  const page = data?.allBuilderModels.landingPage[0].content;

  return (
    <>
      <BuilderComponent renderLink={Link} name="landing-page" content={page} />
    </>
  );
}

export default HomePage;

export const HomePageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      landingPage(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;

export const MusicViewer = () => {
  const defaultSong = { title: "", url: "", desc: "", genre: "" };

  const [songList, setSongList] = useState([]);
  const [genreSelected, setGenreSelected] = useState("");
  const [songSelected, setSongSelected] = useState(defaultSong);
  const [songIndex, setSongIndex] = useState("");
  const [songPlaying, setSongPlaying] = useState(false);
  const [showMusicPlayer, setShowMusicPlayer] = useState(false);
  const [initialGenreClicked, setInitialGenreClicked] = useState(false);

  const musicList = useRef(null);
  const player = useRef(null);

  function selectGenre(index, playInitialSong = false) {
    setSongList(index);
    setGenreSelected(true);

    if (!initialGenreClicked) {
      selectSong(0, MusicConfig[index].playlist[0]);
      setInitialGenreClicked(true);
    }

    if (playInitialSong) {
      selectSong(0, MusicConfig[index].playlist[0]);
    }

    setTimeout(() => {
      player.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 0.5);
  }

  function selectSong(index, song) {
    console.log("setSongSelected is called with", index, song);
    setSongSelected(song);
    setSongIndex(index);
    setShowMusicPlayer(true);
    setSongPlaying(true);

    setTimeout(() => {
      player.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1);
  }

  function SongEnded() {
    //last song logic
    if (songList === 19 && songIndex === 3) {
      selectGenre(0, true);
      return;
    }

    console.log(MusicConfig[songList], "MusicConfig[songList]");
    console.log(MusicConfig[songList].playlist, "playlist");
    console.log(songIndex);

    if (MusicConfig[songList].playlist.length === 1) {
      selectGenre(songList + 1);
      selectSong(0, MusicConfig[songList + 1].playlist[0]);
    }
    if (MusicConfig[songList].playlist.length > 1) {
      if (songIndex + 1 === MusicConfig[songList].playlist.length) {
        selectGenre(songList + 1);
        selectSong(0, MusicConfig[songList + 1].playlist[0]);
      } else {
        console.log("else baby", MusicConfig[songList + 1]);

        selectSong(
          songIndex + 1,
          MusicConfig[songList].playlist[songIndex + 1]
        );
      }
    }
  }

  function onPlay() {
    setSongPlaying(true);
  }

  const onPause = () => {
    setSongPlaying(false);
  };

  const SplitSongTitle = (songSelected = "", genre = "") => {
    const [songName, artist] = songSelected.split("-");
    console.log(genre, "genre");
    console.log(songSelected, "songSelected");
    return (
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <S.SongName>
          <span>{songName}</span>
        </S.SongName>
        <S.SongName>
          <span>{artist}</span>
        </S.SongName>
        <S.SongName>
          <span>{genre}</span>
        </S.SongName>
      </div>
    );
  };

  function getList(genreIndex) {
    const songs = MusicConfig[genreIndex].playlist.map((song) => {
      const [songName, artist] = song.title.split("-");

      return { songName, artist, song };
    });
    return (
      <>
        {songs.map(({ songName, artist, song }, index) => (
          <S.Song
            className={songIndex === index ? "selectedSong" : false}
            key={index}
            onClick={(e) => selectSong(index, song)}
          >
            <span style={{ paddingRight: "5px" }}>{songName}</span>
            <span style={{ paddingRight: "5px" }}>{artist}</span>
            <span>{song.genre}</span>
          </S.Song>
        ))}
      </>
    );
  }

  console.log(songSelected, "songSelected");
  return (
    <S.Main>
      <S.StyledContainer>
        {MusicConfig.map((mConfig, index) => (
          <S.Genre
            className={songList === index ? "selectedGenre" : false}
            key={index}
            onClick={() => selectGenre(index)}
          >
            {mConfig.genre}
          </S.Genre>
        ))}
      </S.StyledContainer>
      <S.GenreContent>
        {genreSelected && (
          <S.MusicListContainer ref={musicList}>
            {/* <S.Header>Tracks on this playlist</S.Header> */}
            <div style={{ display: "flex" }}>
              <S.Header>Title</S.Header>
              <S.Header>Artist</S.Header>
              <S.Header>Genre</S.Header>
            </div>
            <S.GroupedSongs>
              {genreSelected && getList(songList)}
            </S.GroupedSongs>
          </S.MusicListContainer>
        )}

        {songSelected.url !== "" && showMusicPlayer && (
          <S.SelectedSongContainer ref={player}>
            <S.Header
              style={{
                marginBottom: "10px",
                fontWeight: "600",
                width: "8em",
              }}
            >
              Now Playing
            </S.Header>
            {SplitSongTitle(songSelected.title, songSelected.genre)}
            <S.Header
              style={{
                marginBottom: "10px",
                fontWeight: "600",
              }}
            >
              Description
            </S.Header>
            <S.Description style={{ color: "#b28f15" }}>
              {songSelected.desc}.
            </S.Description>
            <S.Description style={{ color: "#b28f15", marginBottom: "5px" }}>
              {songSelected.elements}
            </S.Description>
            <S.StyledReactPlayer
              url={songSelected.url}
              width="100%"
              height="fit-content"
              controls={true}
              config={{
                file: {
                  forceAudio: true,
                  attributes: {
                    controlsList: "noplaybackrate nodownload nofullscreen",
                  },
                },
              }}
              onEnded={() => SongEnded()}
              playing={songPlaying}
              onPlay={() => onPlay()}
              onPause={() => onPause()}
            />
          </S.SelectedSongContainer>
        )}
      </S.GenreContent>
    </S.Main>
  );
};
Builder.registerComponent(MusicViewer, {
  name: "MusicViewer",
  inputs: [{ name: "title" }],
});

export const ImageGallery = ({ photos }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery
        direction="column"
        columns={2}
        photos={photos}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

Builder.registerComponent(ImageGallery, {
  name: "ImageGallery",
  inputs: [
    {
      name: "photos",
      type: "list",
      subFields: [
        {
          name: "src",
          type: "file",
          required: true,
          allowedFileTypes: ["jpeg", "png", "svg"],
          helperText: "Image",
        },
        { name: "width", type: "number", required: true, defaultValue: "1" },
        { name: "height", type: "number", required: true, defaultValue: "1" },
      ],
      required: true,
      defaultValue: [{ title: "", content: "" }],
    },
  ],
});
